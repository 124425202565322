import React, { useContext } from 'react';
import { useLocalStorage } from './useLocalStorage';

const LanguageContext = React.createContext(null);

export function LanguageContextProvider({ children }) {
  const [language, setLanguage] = useLocalStorage('lang', { lang: 'en' });

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within a LanguageContextProvider');
  }

  return context;
}
