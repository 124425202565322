import React, { useState, useEffect } from 'react';
import Toast from '@ui/components/Toast';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const FloatingAlertWrapper = styled.div`
  position: fixed;
  top: 14px;
  right: 14px;
  bottom: inherit;
  z-index: 999;
  width: 375px;

  @media (max-width: 992px) {
    top: inherit;
    bottom: 90px;
  }
`;

const NetworkStatus = ({ children }) => {
  const { t } = useTranslation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <FloatingAlertWrapper>
        {isOnline ? null : (
          <Toast variant="warning">
            <b>{t('Connection Issue')}</b>
            <br />

            {t(`We're having trouble connecting. Please check your network and try again later.`)}
          </Toast>
        )}
      </FloatingAlertWrapper>

      {children}
    </>
  );
};

export default NetworkStatus;
