import axios from 'axios';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

const API = `${process.env.REACT_APP_API_BASE_URL}/referral`;

export const useReferralEarnings = () =>
  useQuery({
    queryKey: ['referralEarnings'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/earnings`);

      return data;
    }
  });

export const useReferralEarningsHistory = () => {
  return useInfiniteQuery({
    queryKey: ['referralEarningsHistory'],
    queryFn: async ({ pageParam }) => {
      const { data } = await axios.get(
        `${API}/earnings/history?${
          pageParam?.page ? new URLSearchParams(pageParam.page).toString() : ''
        }`
      );

      return {
        data: data?.items ?? [],
        lastEvaluatedKey: data?.lastEvaluatedKey ?? null
      };
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.lastEvaluatedKey) {
        return null;
      }

      return {
        page: lastPage.lastEvaluatedKey
      };
    }
  });
};

export const useReferralUsers = () => {
  return useInfiniteQuery({
    queryKey: ['referralUsers'],
    queryFn: async ({ pageParam }) => {
      const { data } = await axios.get(
        `${API}/users?${pageParam?.page ? new URLSearchParams(pageParam.page).toString() : ''}`
      );

      return {
        data: data?.items ?? [],
        lastEvaluatedKey: data?.lastEvaluatedKey ?? null
      };
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.lastEvaluatedKey) {
        return null;
      }

      return {
        page: lastPage?.lastEvaluatedKey
      };
    }
  });
};

export const startEarningsWithdrawal = async () => {
  return await axios.post(`${API}/earnings/withdrawal`, {});
};

export const trackClick = async (clickType, referralCode) => {
  return await axios.post(`${API}/track/click/${clickType}/${referralCode}`, {});
};

export const trackPartnerClick = async (clickType, referralCode, partnerReferralCode) => {
  return await axios.post(
    `${API}/track/click/partner/${clickType}/${referralCode}/${partnerReferralCode}`,
    {}
  );
};
