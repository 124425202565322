import { Suspense } from 'react';

import Spinner from '../Spinner';

const Loadable = (Component) =>
  function (props) {
    return (
      <Suspense fallback={<Spinner />}>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;
