import React, { useContext, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { MONEVO_CATEGORIES } from 'constants/index';

import { useSessionStorage } from '@ui/hooks/useSessionStorage';
import { syncGuest } from '../services/guest';

const UserGuest = React.createContext(null);

const USER_ID = uuidv4();

console.log('USER_ID', USER_ID);

export function UserGuestContextProvider({ children }) {
  const [user, setValue, clearUser] = useSessionStorage('GUEST_USER', {
    user_id: USER_ID
  });
  const [monevoData, setMonevoData, clearMonevoData] = useSessionStorage(
    'GUEST_USER_MONEVO_DATA',
    {}
  );

  const [isSkippingPersonalData, setIsSkippingPersonalData] = useState(false);
  const isUserProfilePreferencesCompleted = useMemo(() => !!user?.preferences, [user]);
  const isUserProfileCompleted = useMemo(() => !!user?.profile_setup_completed, [user]);

  const isPersonalDataCompleted = useMemo(() => {
    const preferences = user?.preferences?.personal?.values;

    if (preferences?.length) {
      if (!preferences.find(({ id }) => MONEVO_CATEGORIES.includes(id))) {
        return true;
      }
      return !!monevoData?.personal_data;
    }

    return !!monevoData?.personal_data;
  }, [user, monevoData]);

  const onSkipPersonalData = () => {
    setIsSkippingPersonalData(true);
  };

  const updateProfile = async (data) => {
    console.log('updateProfile', data);

    setValue({
      ...user,
      ...data
    });

    await syncGuest({
      ...user,
      ...data,
      monevo_data: monevoData
    });
  };

  const updateMonevoData = async (data) => {
    console.log('updateMonevoData', data);

    setMonevoData({
      ...monevoData,
      ...data
    });

    await syncGuest({
      ...user,
      monevo_data: {
        ...monevoData,
        ...data
      }
    });
  };

  const hasCompletedProfile = useMemo(() => !!monevoData?.hasCompletedProfile, [monevoData]);

  return (
    <UserGuest.Provider
      value={{
        user,
        isUserProfilePreferencesCompleted,
        isUserProfileCompleted,
        isPersonalDataCompleted,
        isSkippingPersonalData,
        monevoData,
        updateProfile,
        updateMonevoData,
        hasCompletedProfile,
        onSkipPersonalData,
        clearUser,
        clearMonevoData
      }}
    >
      {children}
    </UserGuest.Provider>
  );
}

export function useUserGuest() {
  const context = useContext(UserGuest);

  if (!context) {
    throw new Error('useUserGuest must be used within a UserGuestContextProvider');
  }

  return context;
}
