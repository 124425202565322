import en from './English.json';
import es from './Spanish.json';
import pt from './Portuguese.json';
import de from './German.json';
import fr from './French.json';
import no from './Norwegian.json';
import se from './Swedish.json';
import it from './Italian.json';
import fi from './Finnish.json';
import nl from './Dutch.json';
import ro from './Romanian.json';
import pl from './Portuguese.json';
import dk from './Danish.json';

export const languages = {
  en,
  es,
  pt,
  de,
  fr,
  no,
  se,
  it,
  fi,
  nl,
  ro,
  pl,
  dk
};
