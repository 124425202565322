import React, { useContext } from 'react';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';

const FlagsContext = React.createContext(null);

export function FlagsContextProvider({ children }) {
  const ldFlags = useLDFlags();

  return (
    <FlagsContext.Provider
      value={{
        isPassportEnabled: ldFlags.isPassportEnabled,
        isPassportOnlyEnabled: ldFlags.isPassportOnlyEnabled,
        metrixsEnabled: ldFlags.isMetrixsEnabled,
        cashbacksEnabled: ldFlags.isCashbackEnabled,
        referralEnabled: true, //ldFlags.isReferralEnabled,
        directIdEnabled: ldFlags.isDirectIdEnabled,
        registerFunnelDynamicDistribution: ldFlags.registerFunnelDynamicDistribution,
        mortgageOfferDynamicDistribution: ldFlags.mortgageOfferDynamicDistribution,
        isCurrencyExchangeEnabled: ldFlags.isCurrencyExchangeEnabled
      }}
    >
      {children}
    </FlagsContext.Provider>
  );
}

export function useFlags() {
  const context = useContext(FlagsContext);

  if (!context) {
    throw new Error('useReferral must be used within a FlagsContextProvider');
  }

  return context;
}
